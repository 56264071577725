* {
  box-sizing: border-box;
}

:root {
  --dots: rgba(242, 242, 242, 0.6);
  --shine: rgba(242, 242, 242, 0);
  /* --shine: rgba(242, 242, 242, 0.35); */
  /* --lens: #202020; */
  --lens: url(#gradient2);
  --code-one: #66fcf1;
  --code-two: #45a29e;
  --code-three: #c5c6c7;
  --code-four: #f7f8fa;
  --frame: url(#gradient);
  --color-brand2: #232733;
  --color-brand-secondary2: #111111;
  --color-brand: white;
  --color-brand-secondary: #1c1f29;
}

.gradient__brand-secondary {
  stop-color: var(--color-brand-secondary);
}

.gradient__brand {
  stop-color: var(--color-brand);
}

.gradient__brand-secondary2 {
  stop-color: var(--color-brand-secondary2);
}

.gradient__brand2 {
  stop-color: var(--color-brand2);
}

/* #0b0c10 '#1f2833', '#c5c6c7', '#45a29e', '', '#f7f8fa'  */

.lensWrapper {
  text-align: center;
  width: 100%;
}

.lensWrapper svg {}

.code-block {
  animation: read 1.75s ease-in-out;
  -webkit-animation: read 1.75s ease-in-out;
  animation-iteration-count: 1;
}

/* 
.animatebBlock {
  animation: read 1.75s ease-in-out;
  -webkit-animation: read 1.75s ease-in-out;
  animation-iteration-count: 1;
} */

svg:hover .code-block {
  /*animation-play-state: running;
  */
  animation: read 1.75s linear;
  -webkit-animation: read 1.75s linear;
  animation-iteration-count: infinite;
}

.code-block rect {
  fill: var(--code-one);
}

.code-block rect:nth-of-type(15), .code-block rect:nth-of-type(17) {
  fill: var(--code-four);
}

.code-block rect:nth-of-type(1) {
  fill: var(--code-three);
}

.code-block rect:nth-of-type(5) {
  fill: var(--code-four);
}

.code-block rect:nth-of-type(8), .code-block rect:nth-of-type(9) {
  fill: var(--code-two);
}

.code-block rect:nth-of-type(12) {
  fill: var(--code-three);
}

.lens {
  fill: var(--lens);
}

.lens__shine {
  fill: var(--shine);
}

.shades-dot {
  fill: var(--dots);
}

.shades-frame {
  fill: var(--frame);
}

@-moz-keyframes read {
  to {
    transform: translate(0, -283.5px);
  }
}

@-webkit-keyframes read {
  to {
    transform: translate(0, -283.5px);
  }
}

@-o-keyframes read {
  to {
    transform: translate(0, -283.5px);
  }
}

@keyframes read {
  to {
    transform: translate(0, -283.5px);
  }
}