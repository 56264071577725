* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: 'Encode Sans Expanded', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  /* font-family: 'Poppins', sans-serif; */
}

body {
  background-color: #010606;
}

/* h1 {
  font-family: 'Archivo Black', sans-serif;
  font-size: 54px;
  line-height: 40px;
  letter-spacing: -0.8em;
  font-weight: 800;
} */

/* scroll bar updates */

/* width */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.close {
  width: 30px;
  height: 30px;
}

/* hide scrolling but allows scrolling */

html {
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}